$(document).ready(function() {

    if ( $(window).scrollTop() > 250 ) {
        $('header.header').addClass('sticky active');
    }

    $(window).scroll(function() {
        var scrollTop = $(window).scrollTop();

        $('header.header nav.navbar .navbar-nav.main-sub-menu .nav-item.active').removeClass('active');
        $('#mega-menu').hide();
        $('#mega-menu .mega-menu-categories.active').removeClass('active');

        if ( scrollTop > 250 ) {
            $('header.header').addClass('sticky');
            setTimeout(function(){ $('header.header').addClass('active'); }, 10);
        } else {
            if ($('header.header').hasClass('sticky') && scrollTop < 30) {
                $('header.header').removeClass('sticky');
                setTimeout(function(){ $('header.header').removeClass('active'); }, 10);
            }
        }

        if ($(window).width() > 1440) {
            $('#mega-menu').css('top', (193 - scrollTop) + 'px');
        } else {
            $('#mega-menu').css('top', (173 - scrollTop) + 'px');
        }
    });


    $('header.header nav.navbar .navbar-nav.main-sub-menu .nav-item').mouseenter(function () {
        if ($(window).width() > 991) {
            if ($(this).hasClass('active')) {
                var category_id = $(this).find('.nav-link').data('id');
                $('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').addClass('active');
            } else {
                var category_id = $(this).find('.nav-link').data('id');
                $('#mega-menu .mega-menu-categories.active').removeClass('active');
                $('header.header nav.navbar .navbar-nav.main-sub-menu .nav-item.active').removeClass('active');
                $(this).addClass('active');

                if ($('#mega-menu').is(':visible') == false) {
                    if ($('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').length > 0) {
                        $('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').addClass('active');
                        $('#mega-menu').slideToggle(400, function () {
                            //$('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').fadeIn().trigger("refresh.owl.carousel");
                        });
                    }
                } else {
                    //$('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').fadeIn().trigger("refresh.owl.carousel");

                    if ($('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').length > 0) {
                        $('#mega-menu .mega-menu-categories[data-id="' + category_id + '"]').addClass('active');
                    } else {
                        $('#mega-menu').slideToggle(400);
                    }
                }
            }
        }
    });

    $(document).on('mouseleave', '#mega-menu', function(e) {
        reltarget = $(e.relatedTarget);

        if (!reltarget.hasClass('nav-link') && !reltarget.hasClass('nav-item') && !reltarget.hasClass('navbar')) {
            $('header.header nav.navbar .navbar-nav.main-sub-menu .nav-item.active').removeClass('active');
            $('#mega-menu').slideToggle(400, function () {
                $('#mega-menu .mega-menu-categories:visible').hide();
            });
        }
    });

    /*$('#mega-menu .mega-menu-categories').owlCarousel({
        items: 6,
        dots: true,
        loop: false,
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 4
            },
            1200: {
                items: 6
            }
        }
    });*/

    if ($('section.block-slider').length > 0) {
        $('section.block-slider .owl-carousel').owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            items: 1,
            autoplay:true,
            autoplayTimeout: 5000,
            stopOnHover: true,
            smartSpeed: 1000,
        });
    }

    if ($('section.block-slider-product-categories').length > 0) {
        $('section.block-slider-product-categories .owl-carousel').owlCarousel({
            items: 4,
            dots: true,
            loop: false,
            autoplay:true,
            margin: 30,
            autoplayTimeout: 3000,
            stopOnHover: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }
        });
    }

    if ($('section.block-slider-last-news').length > 0) {
        $('section.block-slider-last-news .owl-carousel').owlCarousel({
            items: 3,
            dots: true,
            loop: false,
            autoplay:false,
            margin: 30,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
    }

    // OFI
    objectFitImages();
});